/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://unpkg.com/tailwindcss/dist/base.css
 */
@tailwind base;

/**
 * Remove the default box-shadow for invalid elements to prevent
 * inputs in Livewire components showing with a
 * red border by default in Firefox.
 *
 * See: https://github.com/laravel-frontend-presets/tall/issues/7
 */
 input:invalid, textarea:invalid, select:invalid {
    box-shadow: none;
}

/**
 * This injects any component classes registered by plugins.
 */
@tailwind components;

/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 */

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 */
@tailwind utilities;

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 */

[x-cloak] {
    display: none!important;
}

.tags-input {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    border-width: 1px;
    border-radius: .25rem;
    padding-left: .5rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .25rem;
    }

    .tags-input-tag {
    display: inline-flex;
    line-height: 1;
    align-items: center;
    font-size: .875rem;
    background-color: #bcdefa;
    color: #1c3d5a;
    border-radius: .25rem;
    user-select: none;
    padding: .25rem;
    margin-right: .5rem;
    margin-bottom: .25rem;
    }

    .tags-input-tag:last-of-type {
    margin-right: 0;
    }

    .tags-input-remove {
    color: #2779bd;
    font-size: 1.125rem;
    line-height: 1;
    }

    .tags-input-remove:first-child {
    margin-right: .25rem;
    }

    .tags-input-remove:last-child {
    margin-left: .25rem;
    }

    .tags-input-remove:focus {
    outline: 0;
    }

    .tags-input-text {
    flex: 1;
    outline: 0;
    padding-top: .25rem;
    padding-bottom: .25rem;
    margin-left: .5rem;
    margin-bottom: .25rem;
    min-width: 10rem;
    }

    .py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
    }

/**
*taggle.js css below
*
*/

// .textarea {
//     width: 100%;
//     height: 300px;
//     border: 1px solid red;
//     padding: 8px;
// }
//
// .taggle_list {
//     padding: 0;
//     margin: 0;
//     line-height: 2.5;
//     width: 100%;
//     li {
//         display: inline;
//         vertical-align: baseline;
//         white-space: nowrap;
//         font-weight: 500;
//         margin-bottom: 5px;
//     }
//     .taggle {
//         margin-right: 8px;
//         background: #E2E1DF;
//         padding: 5px 10px;
//         border-radius: 3px;
//         position: relative;
//         cursor: pointer;
//         transition: all .3s;
//         -webkit-animation-duration: 1s;
//         animation-duration: 1s;
//         -webkit-animation-fill-mode: both;
//         animation-fill-mode: both;
//         .close {
//             font-size: 1.1rem;
//             position: absolute;
//             top: 10px;
//             right: 3px;
//             text-decoration: none;
//             padding: 0;
//             line-height: 0.5;
//             color: #ccc;
//             color: rgba(0, 0, 0, 0.2);
//             padding-bottom: 4px;
//             display: inline-block;
//             opacity: 0;
//             pointer-events: none;
//             border: 0;
//             background: none;
//             cursor: pointer;
//             &:hover {
//                 color: #990033;
//             }
//         }
//         &:hover {
//             padding: 5px;
//             padding-right: 15px;
//             background: #ccc;
//             transition: all .3s;
//         }
//         &>.close {
//             opacity: 1;
//             pointer-events: auto;
//         }
//     }
//     .taggle_hot {
//         background: #cac8c4;
//     }
// }
//
// .taggle_placeholder {
//     position: absolute;
//     color: #CCC;
//     top: 24px;
//     left: 16px;
//     transition: opacity, .25s;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
// }
//
// .taggle_input {
//     // display: block;
//     width: 100%;
//     padding: 0.75rem;
//     border: 1px solid rgba(107, 114, 128, 1);
//     border-radius: 0.375rem;
//     --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
//     box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
//     color: #000;
//     &:focus {
//         --tw-ring-color: rgb(173, 175, 179);
//         --tw-ring-inset: inset;
//         box-shadow: var(--tw-ring-inset) 0 0 0 1px var(--tw-ring-color);
//         opacity: 0.5;
//         border: 1px solid rgba(0, 0, 0, 1);
//         color: #000;
//         padding: 0.25rem;
//         background-color: #f5f5f5;
//     }
// }
//
// .taggle_sizer {
//     padding: 0;
//     margin: 0;
//     position: absolute;
//     top: -500px;
//     z-index: -1;
//     visibility: hidden;
// }
